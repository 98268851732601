import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";
import useForm from "../../../hooks/common/useForm";
import usePaf from "../../../hooks/billing/usePaf";
import Loader from "../../common/Loader";
import { SubmitButton } from "../../common/Button";
import Table from "../../common/Table";
import Error from "../../common/Error";
import FormInput from "../../common/FormInput";
import { Visibility } from "../../common/Visibility";
import { PUBLIC_APP_USER } from "../../../constants/webapp";
import { Alert } from "antd";
import { useToasts } from "react-toast-notifications";
import SelectInput from "../../common/SelectInput";
import { API_ALL_BANK_CODES } from "../../../constants/api";
import useGenericGet from "../../../hooks/common/useGenericGet";
import CancelPrnDialog from "../../dialog/CancelPrnDialog";
import { returnObject } from "../../../utils/helperUtil";

const Paf = (props) => {
  const { status, permissions, id } = props;
  const {
    invoice_id: invoice_id = id,
    tendered,
    amount,
    advanced,
    tax,
  } = useParams();
  const { form, updateFormValue, onChange } = useForm({});
  const { addToast } = useToasts();
  const { data: bankCodes, loadData: getBankCodes } = useGenericGet();
  const [filters, setFilter] = useState({ status });

  const [errorCancel, setErrorCancel] = useState(false);
  const [successCancel, setSuccessCancel] = useState(false);

  const {
    loading,
    invoice,
    prns,
    success,
    submitting,
    error,
    getModes,
    getInvoice,
    getPrns,
    pafColumns,
    pafTableData,
    createPaf,
    sendPrn,
    open,
    setOpen,
    ID,
    cancelApi,
  } = usePaf();
  // const { viewData } = useProjects();

  useEffect(() => {
    getBankCodes({ api: API_ALL_BANK_CODES });
  }, []);
  useEffect(() => {
    if (invoice_id) {
      getInvoice(invoice_id);
      getPrns({
        invoice: invoice_id,
        for_tendered: parseInt(tendered) === 1 ? true : false,
        time: new Date().toLocaleString(),
      });
    }
    getModes();
    // eslint-disable-next-line
  }, [invoice_id]);

  useEffect(() => {
    if (error) {
      addToast(error?.amount?.amount || "Failed to Resend PRN", {
        appearance: "error",
        autoDismiss: true,
      });
    }

    if (sendPrn) {
      addToast(sendPrn?.message, {
        appearance: "success",
        autoDismiss: true,
      });
    }
  }, [error, sendPrn]);

  useEffect(() => {
    if (errorCancel) {
      addToast("Failed to cancel PRN!", {
        appearance: "error",
        autoDismiss: true,
      });
    }

    if (successCancel) {
      addToast("You have succesfully cancelled a PRN", {
        appearance: "success",
        autoDismiss: true,
      });
      window.location.reload(true);
    }
  }, [successCancel, errorCancel]);

  useEffect(() => {
    if (invoice) {
      updateFormValue(
        "amount",
        Number(invoice?.amount) - Number(invoice?.amount_paid)
      );
    }
  }, [invoice]);

  useEffect(() => {
    if (form?.payment_criteria) {
      updateFormValue("payment_amount", form?.payment_criteria);
    }
  }, [form?.payment_criteria]);

  const modes = [
    {
      name: "Online Payment Options (VISA, MasterCard, American Express, Union Pay, Mobile Money)",
      value: "ONLINE",
    },
    {
      name: "Payment Transfer Instructions (EFT, RTGS and Swift)",
      value: "EFT",
    },
    {
      name: "Other Payment Options (Cash, Cheque, Mobile Money, Demand draft, Point of Sale)",
      value: "CASH",
    },
  ];

  const payment_criteria = [
    {
      name: "Full Payment",
      value: form?.amount,
    },
    {
      name: "Half Installment",
      value: 0.5 * form?.amount,
    },
    {
      name: "Quarterly Installment",
      value: 0.25 * form?.amount,
    },
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    createPaf({
      invoice: invoice_id,
      payment_mode: form?.payment_mode,
      ...(form?.payment_mode === "EFT" ? { bank_code: form?.bank_code } : {}),
      ...returnObject(tax === "park_user_fees", {
        amount: Math.round(form?.payment_amount) || form?.amount,
        for_commercial_vehicle: 1,
      }),
      ...returnObject(tax !== "park_user_fees", {
        amount: amount ? Math.round(amount) : Math.round(form?.amount),
      }),
      for_tendered: parseInt(tendered) === 1 ? true : false,
      for_advanced: parseInt(advanced) === 1 ? true : false,
    });
  };

  if (permissions === PUBLIC_APP_USER)
    return (
      <div id="w4-container" className="table-responsive kv-grid-container">
        <Table
          tableData={pafTableData(status, prns || [])}
          tableColumns={pafColumns}
          tableTitle="Prns"
        />
      </div>
    );

  if (loading) return <Loader />;
  
  return (
    <div id="content">
      <div className="outer">
        <div className="inner bg-light lter">
          {/* <ul className="breadcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li className="active">
              Create PRN for {invoice?.invoice_object?.name}
            </li>
          </ul> */}
          <div className="prn-create">
            <div className="prn-form" style={{ margin: "1%" }}>
              <Visibility
                show={
                  invoice?.payment_status != "paid" || parseInt(tendered) === 1
                }
              >
                <div className="row">
                  <form onSubmit={onSubmit}>
                    {error && <Error message={error} />}
                    {success && (
                      <Alert
                        style={{ marginBottom: 10 }}
                        message={success}
                        type="success"
                        showIcon
                      />
                    )}

                    <Alert
                      style={{ marginBottom: 20 }}
                      message={
                        <>
                          Amount Due is: UGX{" "}
                          {amount
                            ? Number(amount).toLocaleString()
                            : (
                                Number(invoice?.amount) -
                                  Number(invoice?.amount_paid) || 0
                              ).toLocaleString()}
                          /=
                        </>
                      }
                      type="info"
                      showIcon
                    />
                    <Visibility show={tax === "park_user_fees"}>
                      <div className="form-group field-paf-amount required">
                        <SelectInput
                          options={payment_criteria?.map((sup) => ({
                            label: sup.name,
                            value: sup.value,
                          }))}
                          name="payment_criteria"
                          label="Payment Criteria"
                          onChange={(value) =>
                            updateFormValue("payment_criteria", value)
                          }
                          value={form?.payment_criteria || ""}
                          required
                        />
                      </div>
                      <div className="form-group field-paf-amount required">
                        <FormInput
                          label="New Amount"
                          name="payment_amount"
                          onChange={onChange}
                          value={form?.payment_amount || form?.amount}
                          error={error}
                          required
                          disabled={true}
                        />
                      </div>
                    </Visibility>
                    <Visibility show={tax !== "park_user_fees"}>
                      <div className="form-group field-paf-amount required">
                        <FormInput
                          label="Amount"
                          name="amount"
                          onChange={onChange}
                          value={Number(amount) ? Number(amount) : form?.amount}
                          error={error}
                          required
                          disabled={
                            tax == ["lst"] || tax == ["property"] ? false : true
                          }
                        />
                      </div>
                    </Visibility>
                    {modes.map((mode, idx) => (
                      <div
                        className="form-group field-paf-payment_mode"
                        key={idx}
                      >
                        <label>
                          <input
                            name="payment_mode"
                            type="radio"
                            onClick={() =>
                              updateFormValue("payment_mode", mode.value)
                            }
                            required
                          />{" "}
                          {mode.name}
                        </label>
                        <div className="help-block" />
                      </div>
                    ))}
                    <Visibility show={form?.payment_mode == "EFT"}>
                      <SelectInput
                        options={(bankCodes || []).map((sup) => ({
                          label: sup.bank_name,
                          value: sup.code,
                        }))}
                        id="bank_code"
                        name="bank_code"
                        label="URA Banks"
                        onChange={(value) =>
                          updateFormValue("bank_code", value)
                        }
                        value={form?.bank_code || ""}
                        maxMenuHeight={250}
                        error={error}
                        required
                      />
                    </Visibility>

                    <div className="form-group">
                      <SubmitButton
                        submitting={submitting}
                        text="Create"
                        submitText="Creating"
                      />
                    </div>
                  </form>
                </div>
              </Visibility>
              <div
                id="w4-container"
                className="table-responsive kv-grid-container"
              >
                <CancelPrnDialog
                  open={open}
                  setOpen={setOpen}
                  ID={ID}
                  cancelApi={cancelApi}
                  setErrorCancel={setErrorCancel}
                  setSuccessCancel={setSuccessCancel}
                />
                <Table
                  tableData={pafTableData(status, prns || [])}
                  tableColumns={pafColumns}
                  onFilterChange={setFilter}
                  filters={filters}
                  tableTitle="Prns"
                />
              </div>
              {/* <div className="row">
                <h4>Information About this Registration</h4>
                <View viewData={viewData(invoice?.invoice_object || {})} />
              </div> */}
            </div>
          </div>
          <div id="react-content"> </div>
        </div>
      </div>
    </div>
  );
};

Paf.propTypes = {
  status: PropTypes.string.isRequired,
};

export default Paf;
