import { Button, Collapse, DatePicker, Select } from "antd";
import SelectInput from "../../common/SelectInput";
import styled from "styled-components";
import { Wrapper } from "../../elements/Styled";
import { useAppContext } from "../../../context/AppContext";
import { setPickerDate } from "../../../utils/helperUtil";
import { GetPermissions } from "../../../utils/permissionsUtils";
import { HasUserType } from "../../../utils/authUtils";
import {
  APP_CAN_ADMIN,
  APP_CAN_APPROVE,
  CAN_ADMIN,
  CAN_DASHBOARD,
  CAN_DIVISION,
  CAN_PHYSICAL_PLANNING,
  CAN_PHYSICAL_PLANNING_DIVISION,
  VERIFICATION_AUTHORITY,
	LG_MIS_AUTHORITY,
	STATUS_BUDGET_PERFORMANCE,
  STATUS_REVENUE_SHARING_RATIO
} from "../../../constants/webapp";
export const dateFormat = "YYYY-MM-DD";

export const handleReportFilter = (form, setFilter) => {
  let params = "";
  // municipality
  if (form?.municipality) {
    params = `${params}municipality=${form?.municipality}`;
  }
  // division
  if (form?.division) {
    params = `${params}&division=${form?.division}`;
  }
  // parish
  if (form?.parish) {
    params = `${params}&parish=${form?.parish}`;
  }
  // fin year
  if (form?.fin_year) {
    params = `${params}&fin_year=${form?.fin_year}`;
  }
  // Satrt date
  if (form?.start_date) {
    params = `${params}&start_date=${form?.start_date.format(dateFormat)}`;
  }
  // End date
  if (form?.end_date) {
    params = `${params}&end_date=${form?.end_date.format(dateFormat)}`;
  }
  setFilter(params);
};

export const ReportFilter = ({ filter, status }) => {
  const { Panel } = Collapse;
  const { contextData } = useAppContext();
  const { role_name } = contextData;

  const {
    setFilter,
    error,
    financial_years,
		revenue_sources,
    loading,
    form,
    updateFormValue,
    updateFormValues,
    districts,
    divisions,
    parishes,
  } = filter || {};

  const has_division =
    GetPermissions([
      CAN_ADMIN,
      CAN_DASHBOARD,
      APP_CAN_APPROVE,
      CAN_PHYSICAL_PLANNING,
    ]) && role_name !== VERIFICATION_AUTHORITY;

  return (
    <Collapse
      defaultActiveKey={1}
      size="small"
      style={{ marginBottom: 10 }}
      expandIconPosition="end"
    >
      <Panel header="Data Filters" key={1}>
        <div className="flex wrap gap10">
          <RowGrid className="flexEnd gap10">
            <Wrapper className="dashboard-filter">
              <SelectInput
                disabled={HasUserType([LG_MIS_AUTHORITY])?true:!GetPermissions([CAN_ADMIN, CAN_DASHBOARD])}
                options={districts?.map((sup) => ({
                  label: sup.name,
                  value: sup.id,
                }))}
                id="municipality"
                name="municipality"
                label="City / Municipal / District"
                onChange={(value) => updateFormValue("municipality", value)}
                value={form?.municipality || ""}
                error={error}
              />
            </Wrapper>
            
            {status !== STATUS_REVENUE_SHARING_RATIO &&
              <Wrapper className="dashboard-filter">
                <SelectInput
                  disabled={!has_division}
                  options={divisions?.map((sup) => ({
                    label: sup.name,
                    value: sup.id,
                  }))}
                  id="division"
                  name="division"
                  label="Division / Sub-County"
                  onChange={(value) => updateFormValue("division", value)}
                  value={form?.division || ""}
                  maxMenuHeight={250}
                  error={error}
                />
              </Wrapper>
            }
            
            {status !== STATUS_REVENUE_SHARING_RATIO &&
              <Wrapper className="dashboard-filter">
                <SelectInput
                  disabled={
                    !GetPermissions([
                      CAN_ADMIN,
                      CAN_DASHBOARD,
                      APP_CAN_APPROVE,
                      CAN_DIVISION,
                      CAN_PHYSICAL_PLANNING_DIVISION,
                      CAN_PHYSICAL_PLANNING,
                    ])
                  }
                  options={parishes?.map((sup) => ({
                    label: sup.name,
                    value: sup.id,
                  }))}
                  id="parish"
                  name="parish"
                  label="Parish"
                  onChange={(value) => updateFormValue("parish", value)}
                  value={form?.parish || ""}
                  maxMenuHeight={250}
                  error={error}
                />
              </Wrapper>
            }

            <Wrapper>
              <Label>Financial Year</Label>
              <Select
                allowClear
                style={{ width: "100%" }}
                value={form?.fin_year || ""}
                onChange={(value) =>
                  updateFormValue("fin_year", financial_years[value])
                }
                options={financial_years?.map((val, i) => ({
                  label: val,
                  value: i,
                }))}
              />
            </Wrapper>

						{status === STATUS_BUDGET_PERFORMANCE?(
							<Wrapper>
              <Label>Revenue Sources</Label>
              <Select
                allowClear
                style={{ width: "100%" }}
								value={form?.revenue_source_name || ""}
								onChange={(value) =>
									updateFormValues({
										revenue_source: revenue_sources[value]?.source_table,
										revenue_source_name: revenue_sources[value]?.name,
									})
								}
                options={revenue_sources?.map((val, i) => ({
                  label: val.name,
                  value: i,
                }))}
              />
            </Wrapper>
						):<></>}

            <Wrapper>
              <Label>Start Date</Label>
              <DatePicker
                value={setPickerDate(form?.start_date) || ""}
                style={{ width: "100%" }}
                onChange={(value) =>
                  updateFormValue("start_date", value?.format(dateFormat))
                }
              />
            </Wrapper>

            <Wrapper>
              <Label>End Date</Label>
              <DatePicker
                style={{ width: "100%" }}
                value={setPickerDate(form?.end_date) || ""}
                onChange={(value) =>
                  updateFormValue("end_date", value?.format(dateFormat))
                }
              />
            </Wrapper>
          </RowGrid>

          <Wrapper>
            <Button
              type="primary"
              block
              loading={loading}
              onClick={() => setFilter(form)}
            >
              Filter
            </Button>
          </Wrapper>
        </div>
      </Panel>
    </Collapse>
  );
};

const Label = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const InputWrapper = styled.div`
  width: 31.8%;
  @media (max-width: 990px) {
    width: 100%;
  }
`;

const columnWidth = `calc(33.3% - 7px)`;

export const RowGrid = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: ${columnWidth} ${columnWidth} ${columnWidth};

  @media (max-width: 990px) {
    grid-template-columns: 48% 48%;
  }
`;
