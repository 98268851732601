import React, { useEffect } from "react";
import {
  FaAngleDoubleRight,
  FaBan,
  FaEdit,
  FaEye,
  FaGem,
  FaMoneyBill,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  GROUND_RENT,
  STATUS_APPROVED,
  STATUS_PENDING,
  STATUS_REJECTED,
  STATUS_SUBMITTED,
  STATUS_ASSESSED,
  LEASE_STATUS,
  LEASE_DURATION,
  LAND_TENURE_TYPE,
  LAND_TITLE_DESCRIPTION,
  MUN_NAME,
  DIV_NAME,
  PAR_NAME,
  VILL_NAME,
  STR_NAME,
  STATUS_DEACTIVATED,
  AUDIT_AUTHORITY,
} from "../../constants/webapp";
import {
  displayDocuments,
  downloadThroughAnchorLink,
  returnObject,
} from "../../utils/helperUtil";
import { configColor } from "../../components/styled/Theme";
import { Visibility } from "../../components/common/Visibility";
import { MakePaymentRevenueButton } from "../trading-license/useTradingLicense";
import {ActivateButton, DownloadLink} from "../../components/common/Button";
import {
  CONFIGURABLES_API,
  INVOICE_API,
  LAND_USE_API,
  OWNERSHIP_TYPE_API,
} from "../../constants/api";
import { Tooltip } from "antd";
import {
  sDivision,
  sLandPremium,
  sMunicipality,
  sParish,
  sStreet,
  sTaxpayerName,
  sVillage,
} from "../../components/layouts/LeftMenu";
import useLocationData from "../common/useLocationData";
import useGenericGet from "../common/useGenericGet";
import { PanTool } from "@mui/icons-material";
import { taxpayerAmdendables } from "../../utils/statusUtils";
import { useAppContext } from "../../context/AppContext";
import useRevenueSource from "../revenue-source/useRevenueSource";

const useGroundRent = () => {
  const { contextData, updateContext } = useAppContext();

  const userRole = contextData?.role_name;

  const { activateRevenueSource } = useRevenueSource();

  const activateGroundRent = (id) => {
    activateRevenueSource(id, { table_name: "ground_rent", id }, '../ground-rent/assessed')
  }

  const groundRentTableData = (status, data) => [
    ...data.map((item) => {
      const row = {
        application_number: item?.application_number,
        branch_code: item?.branch_code,
        tin: item?.tin,
        email: item?.email,
        mobile: item?.mobile,
        mobile_number: item?.mobile_number,
        owner_name: item?.owner_name,
        nin: item?.nin,
        brn: item?.brn,
        trade_title: item?.trade_title,
        [MUN_NAME]: item?.municipality_name,
        [DIV_NAME]: item?.division_name,
        [PAR_NAME]: item?.parish_name,
        [VILL_NAME]: item?.village_name,
        [STR_NAME]: item?.street_name,
        plot_no: item?.plot_no,
        latitude: item?.r_lat,
        longitude: item?.r_lng,
        land_tenure_type: item?.title_description_name,
        lease_status: item?.lease_status_name,
        ground_rent_amount: Number(item?.ground_rent_amount).toLocaleString(),
        current_premium: Number(item?.current_premium).toLocaleString(),
        lease_duration: item?.lease_duration_name,
        created_by: item?.created_by_name,
        assessments: (
          <Link
            to={`/assessments?tax_id=${item.id}&tax=ground_rent`}
            style={{ color: configColor }}
          >
            Assessment
          </Link>
        ),
        certificate: (
          <DownloadLink
            onClick={() =>
              downloadThroughAnchorLink(
                `${INVOICE_API}print-registration-certificate/?id=${item?.id} &table_name=ground_rent`,
                "Registration Certificate"
              )
            }
            title="[Registration Certificate]"
          />
        ),
        prn: (
          <MakePaymentRevenueButton
            api={GROUND_RENT}
            id={item?.id}
            revenue_source={"ground_rent"}
          />
        ),

        actions: (
          <div style={{ whiteSpace: "nowrap" }}>
            <div style={{ whiteSpace: "nowrap" }}>
              <Link
                class="mr-2"
                style={{ padding: 5, paddingBottom: 0 }}
                to={`${GROUND_RENT}/view/${item.id}`}
                // state={{ tax: "ground_rent" }}
              >
                <FaEye />
              </Link>

              <Visibility
                show={
                  item?.status_name === "DEFERRED" &&
                  userRole !== AUDIT_AUTHORITY
                }
              >
                <Link
                  style={{ padding: 5, paddingBottom: 0 }}
                  class=" mr-2"
                  to={`${GROUND_RENT}/update/${item.id}`}
                >
                  <FaEdit />
                </Link>
              </Visibility>
              <Visibility
                show={
                  ["APPROVED", "PAID"].includes(item?.status_name) &&
                  userRole !== AUDIT_AUTHORITY
                }
              >
                <Tooltip title="Amendment">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`/amendment/ground_rent/${item.id}`}
                  >
                    <FaGem />
                  </Link>
                </Tooltip>

                <Tooltip title="Reassessment">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`/reassessment/ground_rent/${item.id}`}
                  >
                    <FaMoneyBill />
                  </Link>
                </Tooltip>
                <Tooltip title="Business Transfer">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`../business-transfers/request/ground-rents/${item.branch_code}`}
                  >
                    <FaAngleDoubleRight />
                  </Link>
                </Tooltip>
                <Tooltip title="Objection">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`/objection/ground_rent/${item.id}`}
                  >
                    <PanTool sx={{ fontSize: 16 }} />
                  </Link>
                </Tooltip>
                <Visibility
                  show={
                    item?.outstanding_balance == 0 &&
                    userRole !== AUDIT_AUTHORITY
                  }
                >
                  {item?.active_status === 1 && status === "assessed" ? (
                    <Tooltip title="Deactivate">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`${GROUND_RENT}/business_deactivation/${item.id}`}
                      >
                        <FaBan />
                      </Link>
                    </Tooltip>
                  ) : null}
                </Visibility>
                {
                  (status === "deactivated" && contextData.can_admin === 1) ? (
                      <ActivateButton onClick={() => activateGroundRent(item.id)} label="Reactivate" />
                  ) : null
                }
              </Visibility>
            </div>
          </div>
        ),
      };
      return row;
    }),
  ];

  const viewData = (item, isFormData) => {
    return {
      ...returnObject(!isFormData, {
        "Reference Number": item?.application_number,
        "Branch Code": item?.branch_code,
      }),

      ...returnObject(isFormData, {
        "Tax Payer Name": `${item?.first_name} ${item?.surname} `,
      }),

      ...returnObject(!isFormData, {
        [sTaxpayerName]: item?.owner_name,
      }),

      tin: item?.tin,
      nin: item?.nin || item?.cin,
      brn: item?.brn,
      email: item?.email,
      mobile_number: item?.mobile_number,
      [sMunicipality]: isFormData
        ? item?.municipality_name
        : item?.municipality_name,
      [sDivision]: isFormData ? item?.division_name : item?.division_name,
      [sParish]: isFormData ? item?.parish_name : item?.parish_name,
      [sVillage]: isFormData ? item?.village_name : item?.village_name,
      [sStreet]: isFormData ? item?.street_name : item?.street_name,
      latitude: item?.r_lat,
      longitude: item?.r_lng,
      ...returnObject(
        item?.lease_status_name === "Full term lease" ||
          item?.lease_status_name === "Renewal",
        {
          "Lease Volume": item?.lease_volume,
          folio: item?.folio,
        }
      ),
      ownership_type: isFormData
        ? item?.ownership_name
        : item?.ownership_type_name,
      title_description: isFormData
        ? item?.title_name
        : item?.title_description_name,
      land_use: isFormData ? item?.land_use_name : item?.land_use_name,
      lease_duration: isFormData
        ? item?.lease_duration_name
        : item?.lease_duration_name,
      lease_status: isFormData
        ? item?.lease_status_name
        : item?.lease_status_name,
      block: item?.block,
      plot_no: item?.plot_no,
      land_reference_number: item?.land_ref_number,
      land_board_minute_number: item?.land_board_minute_number,
      ...returnObject(item?.title_name === "Condominium Lease Title", {
        "Condominium Plan": item?.condominium_plan,
        "Area of Unit": item?.area_of_unit,
        "Unit Factor": item?.unit_factor,
        "Flat Factor": item?.flat_number,
        "Unit Number": item?.unit_number,
        "Approximate Area In Hectares": item?.approximate_area,
      }),
      [sLandPremium]: Number(item?.current_premium).toLocaleString(),
      ground_rent_amount: Number(item?.ground_rent_amount).toLocaleString(),
      assessed_value: (
        Number(item?.current_premium) + Number(item?.ground_rent_amount)
      ).toLocaleString(),
      ...returnObject(!isFormData, {
        outstanding_balance: Number(
          item?.outstanding_balance
        )?.toLocaleString(),
        // Status: item?.status_details,
      }),
      ...returnObject(!isFormData, {
        "SUPPORTING DOCUMENTS": "",
        ...displayDocuments(item?.ground_rent_attachments),
      }),
      created_by: `${item?.created_by?.first_name} ${item?.created_by?.last_name}`,
    };
  };

  const groundRentTableColumns = (status) => [
    { id: "application_number", header: "Reference Number ", filter: {} },
    ...returnObject(
      status === STATUS_ASSESSED || status === STATUS_DEACTIVATED,
      [{ id: "branch_code", header: "Branch Code", filter: {} }]
    ),
    { id: MUN_NAME, header: "Municipality ", filter: {} },
    { id: DIV_NAME, header: "Division", filter: {} },
    { id: PAR_NAME, header: "Parish ", filter: {} },
    { id: VILL_NAME, header: "Village", filter: {} },
    { id: STR_NAME, header: "Street ", filter: {} },
    { id: "plot_no", header: "Plot Number", filter: {} },
    { id: "owner_name", header: "Tax Payer Name", filter: {} },
    { id: "nin", header: "NIN", filter: {} },
    { id: "brn", header: "BRN", filter: {} },
    { id: "mobile", header: "Mobile Number ", filter: {} },
    { id: "mobile_number", header: "Telephone Number ", filter: {} },
    { id: "land_tenure_type", header: "Title Description", filter: {} },
    { id: "lease_status", header: "Lease Status", filter: {} },
    { id: "lease_duration", header: "Lease Duration", filter: {} },
    { id: "current_premium", header: "Current Premium", filter: {} },
    {
      id: "ground_rent_amount",
      header: "Ground Rent Amount (UGX)",
      filter: {},
    },
    { id: "created_by", header: "Created By", filter: {} },
    ...returnObject(status === STATUS_ASSESSED, [
      { id: "assessments", header: "Invoices" },
      { id: "prn", header: "PRNs" },
      { id: "certificate", header: "Registration Certificate" },
    ]),
    { id: "actions", header: "Actions" },
  ];

  const getActions = (status, approvedID, deferredID, rejectedID) => {
    status = status?.toLowerCase();

    let options = [];
    switch (status) {
      case STATUS_PENDING?.toLowerCase():
        options = [
          { value: approvedID, label: "Approve" },
          { value: rejectedID, label: "Reject" },
          { value: deferredID, label: "Deferred" },
        ];
        break;

      default:
        options = [];
    }

    return options;
  };

  const getRedirect = (status) => {
    let redirect = "";
    switch (status.toLowerCase()) {
      case STATUS_SUBMITTED:
        redirect = `${GROUND_RENT}/${STATUS_PENDING}`;
        break;

      case STATUS_APPROVED:
        redirect = `${GROUND_RENT}/${STATUS_APPROVED}`;
        break;

      case STATUS_REJECTED:
        redirect = `${GROUND_RENT}/${STATUS_REJECTED}`;
        break;

      default:
        break;
    }

    return redirect;
  };

  const amendableFields = [
    ...taxpayerAmdendables,
    {
      id: sMunicipality,
      field: "municipality",
      type: "select",
      options: "districts",
    },
    { id: sDivision, field: "division", type: "select", options: "divisions" },
    { id: sParish, field: "parish", type: "select", options: "parishes" },
    { id: sVillage, field: "village", type: "select", options: "villages" },
    { id: sStreet, field: "street", type: "select", options: "streets" },
    { id: "latitude", field: "r_lat", type: "text" },
    { id: "longitude", field: "r_lng", type: "text" },
    { id: "plot_number", field: "r_plot_no", type: "text" },

    {
      id: "ownership_type",
      field: "ownership_type",
      type: "select",
      options: "ownershiptype",
    },
    {
      id: "title_description",
      field: "title_description",
      type: "select",
      options: "landtitle",
    },
    { id: "land_use", field: "land_use", type: "select", options: "landuse" },
    {
      id: "lease_status",
      field: "lease_status",
      type: "select",
      options: "leasestatus",
    },
    {
      id: "lease_duration",
      field: "lease_duration",
      type: "select",
      options: "leaseduration",
    },
    { id: "block", field: "block", type: "text" },
    { id: "plot_no", field: "plot_no", type: "text" },
    { id: "land_reference_number", field: "land_ref_number", type: "text" },
    {
      id: "land_board_minute_number",
      field: "land_board_minute_number",
      type: "text",
    },
  ];

  const objectionFields = [
    { id: "mobile_number", field: "mobile_number", type: "tel" },
    { id: "tin", field: "tin", type: "text" },
    { id: "owner_name", field: "owner_name", type: "text" },
    { id: "email", field: "email", type: "text" },
    { id: "nin", field: "nin", type: "text" },

    {
      id: sMunicipality,
      field: "municipality",
      type: "select",
      options: "districts",
    },
    { id: sDivision, field: "division", type: "select", options: "divisions" },
    { id: sParish, field: "parish", type: "select", options: "parishes" },
    { id: sVillage, field: "village", type: "select", options: "villages" },
    { id: sStreet, field: "street", type: "select", options: "streets" },
    { id: "latitude", field: "r_lat", type: "text" },
    { id: "longitude", field: "r_lng", type: "text" },
    { id: "plot_number", field: "r_plot_no", type: "text" },

    {
      id: "ownership_type",
      field: "ownership_type",
      type: "select",
      options: "ownershiptype",
    },
    {
      id: "title_description",
      field: "title_description",
      type: "select",
      options: "landtitle",
    },
    { id: "land_use", field: "land_use", type: "select", options: "landuse" },
    {
      id: "lease_status",
      field: "lease_status",
      type: "select",
      options: "leasestatus",
    },
    {
      id: "lease_duration",
      field: "lease_duration",
      type: "select",
      options: "leaseduration",
    },
    { id: "block", field: "block", type: "text" },
    { id: "plot_no", field: "plot_no", type: "text" },
    { id: "land_reference_number", field: "land_ref_number", type: "text" },
    {
      id: "land_board_minute_number",
      field: "land_board_minute_number",
      type: "text",
    },
  ];

  const reassessFields = [
    { id: sLandPremium, field: "current_premium", type: "number" },
    {
      id: "ground_rent_amount",
      field: "ground_rent_amount",
      type: "number",
    },
    {
      id: "assessed_value",
      field: "assessed_value",
      type: "number",
      props: { readOnly: true },
    },
  ];

  return {
    groundRentTableColumns,
    groundRentTableData,
    viewData,
    getRedirect,
    getActions,
    amendableFields,
    reassessFields,
    objectionFields,
  };
};

export const useGroundRentFormSelect = (form, updateFormValue) => {
  const {
    districts,
    getDistricts,
    divisions,
    getDivisions,
    parishes,
    getParishes,
    villages,
    getVillages,
    streets,
    getStreets,
  } = useLocationData();

  const { data: leasestatus, loadData: getLeaseStatus } = useGenericGet();

  const { data: leaseduration, loadData: getLeaseDuration } = useGenericGet();

  const { data: ownershiptype, loadData: getOwnershipType } = useGenericGet();

  const { data: landtenure, loadData: getLandTenureType } = useGenericGet();

  const { data: landtitle, loadData: getTitleDescription } = useGenericGet();

  const { data: landuse, loadData: getLandUse } = useGenericGet();

  useEffect(() => {
    getLandUse({ api: LAND_USE_API });
  }, []);

  useEffect(() => {
    getOwnershipType({ api: OWNERSHIP_TYPE_API });
  }, []);
  useEffect(() => {
    getDistricts();
  }, []);

  useEffect(() => {
    if (form?.municipality) getDivisions(form?.municipality);
  }, [form?.municipality]);

  useEffect(() => {
    if (form?.division) getParishes(form?.division);
  }, [form?.division]);

  useEffect(() => {
    if (form?.parish) getVillages(form?.parish);
  }, [form?.parish]);

  useEffect(() => {
    if (form?.village) getStreets(form?.village);
  }, [form?.village]);

  useEffect(() => {
    getLeaseStatus({
      api: CONFIGURABLES_API,
      params: { field: LEASE_STATUS },
    });
  }, []);

  useEffect(() => {
    getLeaseDuration({
      api: CONFIGURABLES_API,
      params: { field: LEASE_DURATION },
    });
  }, []);

  useEffect(() => {
    getLandTenureType({
      api: CONFIGURABLES_API,
      params: { field: LAND_TENURE_TYPE },
    });
  }, []);

  useEffect(() => {
    getTitleDescription({
      api: CONFIGURABLES_API,
      params: { field: LAND_TITLE_DESCRIPTION },
    });
  }, []);

  useEffect(() => {
    updateFormValue(
      "assessed_value",
      Number(form?.current_premium || 0) + Number(form?.ground_rent_amount || 0)
    );
  }, [form?.current_premium, form?.ground_rent_amount]);

  return {
    districts,
    divisions,
    parishes,
    villages,
    streets,
    leasestatus: leasestatus?.results || [],
    leaseduration: leaseduration?.results || [],
    ownershiptype: ownershiptype?.results || [],
    landtenure: landtenure?.results || [],
    landtitle: landtitle?.results || [],
    landuse: landuse?.results || [],
  };
};

export default useGroundRent;
