import React, { useState } from "react";
import { FaEdit, FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axios";
import { DeleteButton } from "../../components/common/Button";
import {BUSINESS_DEACTIVATION_API} from "../../constants/api";

function useRevenueSource() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  // Delete
  const deleteRevenueSource = (id) => {
    setLoading(true);
    axiosInstance
      .delete(`budgeting/revenue-sources/${id}`)
      .then(() => {
        // handle success
        setLoading(false);
        navigate("../revenue-source", { replace: true });
      })
      .catch((err) => {
        // handle error
        setLoading(false);
        setError(err);
      });
  };
  const revenueSourceTableColumns = [
    { id: "name", header: "Name", filter: {} },
    { id: "source_table", header: "Source Table", filter: {} },
    { id: "coa", header: "Account", filter: {} },
    { id: "actions", header: "Actions" },
  ];

  const revenueSourceTableData = (data) => [
    ...data.map((item) => {
      const row = {
        name: item?.name,
        source_table: item?.source_table?.replaceAll("_", " ").toUpperCase(),
        coa: item?.coa,
        actions: (
          <>
            <Link
              class="mr-2"
              style={{ padding: 5, paddingBottom: 0 }}
              to={`/revenue-source/view/${item.id}`}
            >
              <FaEye />
            </Link>
            <Link
              style={{ padding: 5, paddingBottom: 0 }}
              class=" mr-2"
              to={`/revenue-source/update/${item.id}`}
            >
              <FaEdit />
            </Link>
            <DeleteButton onClick={() => deleteRevenueSource(item.id)} />
          </>
        ),
      };
      return row;
    }),
  ];

  const viewData = (item) => {
    return {
      name: item?.name,
      source_table: item?.source_table?.replaceAll("_", " ").toUpperCase(),
      coa: item?.coa,
    };
  };

  const getRedirect = (status) => {
    let redirect = "";
    switch (status) {
      default:
        redirect = `/revenue-source`;
        break;
    }

    return redirect;
  };

  const activateRevenueSource = (id, params, redirectPath) => {
    setLoading(true);
    axiosInstance
        .post(`${BUSINESS_DEACTIVATION_API}${id}/reactivate-revenue-source/`, {...params})
        .then(() => {
          // handle success
          setLoading(false);
          navigate(redirectPath, { replace: true });
        })
        .catch((err) => {
          // handle error
          setLoading(false);
          setError(err);
        });
  }

  return {
    revenueSourceTableColumns,
    revenueSourceTableData,
    viewData,
    getRedirect,
    loading,
    error,
    activateRevenueSource
  };
}

export default useRevenueSource;
