import React, { useEffect } from "react";
import {
  FaAngleDoubleRight,
  FaBan,
  FaEdit,
  FaEye,
  FaGem,
  FaMoneyBill,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import {
  DIV_NAME,
  MATERIAL_USED,
  AUDIT_AUTHORITY,
  MUN_NAME,
  OUTDOOR_ADVERTISING,
  PAR_NAME,
  STATUS_APPROVED,
  STATUS_ASSESSED,
  STATUS_DEACTIVATED,
  STATUS_DEFFERED,
  STATUS_PENDING,
  STATUS_REJECTED,
  STATUS_SUBMITTED,
  STR_NAME,
  VILL_NAME,
} from "../../constants/webapp";
import {
  downloadThroughAnchorLink,
  returnObject,
  displayDocuments,
} from "../../utils/helperUtil";
import { configColor } from "../../components/styled/Theme";
import { Visibility } from "../../components/common/Visibility";
import useLocationData from "../common/useLocationData";
import useGenericGet from "../common/useGenericGet";
import {
  CONFIGURABLES_API,
  INVOICE_API,
  SIDES_API,
  TOOL_SIZES_API,
  TOOL_TYPES_API,
} from "../../constants/api";
import {ActivateButton, DownloadLink} from "../../components/common/Button";
import { Button, Tooltip } from "antd";
import {
  sDivision,
  sMunicipality,
  sParish,
  sStreet,
  sTaxpayerName,
  sVillage,
  sheightFromGround,
} from "../../components/layouts/LeftMenu";
import { PanTool } from "@mui/icons-material";
import { taxpayerAmdendables } from "../../utils/statusUtils";
import { useAppContext } from "../../context/AppContext";
import useRevenueSource from "../revenue-source/useRevenueSource";

const useOutdoorAdverting = () => {
  const navigate = useNavigate();

  const { contextData } = useAppContext();
  const userRole = contextData?.role_name;

  const { activateRevenueSource } = useRevenueSource();

  const activateOutdoorAdvertising = (id) => {
    activateRevenueSource(id, { table_name: "outdoor_advertising", id }, '../outdoor-advertising/assessed')
  }

  const Assess = (item) => {
    navigate(`/assessments?tax_id=${item.id}&tax=outdoor_advertising`, {
      state: { document_data: item },
    });
  };

  const outdoorAdvertisingTableData = (status, data) => [
    ...data.map((item) => {
      const row = {
        application_number: item?.application_number,
        branch_code: item?.branch_code,
        email: item?.email,
        mobile: item?.mobile_number,
        mobile_number: item?.mobile_number,
        owner_name: item?.owner_name,
        trade_title: item?.trade_title,
        [MUN_NAME]: item?.municipality_name,
        [DIV_NAME]: item?.division_name,
        [PAR_NAME]: item?.parish_name,
        [VILL_NAME]: item?.village_name,
        [STR_NAME]: item?.street_name,
        amount: item?.amount?.toLocaleString(),
        category: item?.category_name,
        tool_type: item?.tool_type_name,
        tool_size: item?.tool_size_name,
        sides: item?.sides_name,
        // material_used: item?.material_used_name,
        created_by: item?.created_by_name,
        assessments: (
          <a
            class="mr-2"
            href=""
            style={{ color: configColor }}
            onClick={() => {
              Assess(item);
            }}
          >
            Assessment
          </a>
        ),
        prn: (
          <MakePaymentRevenueButton
            api={OUTDOOR_ADVERTISING}
            id={item?.id}
            revenue_source={"outdoor_advertising"}
          />
        ),
        certificate: (
          <DownloadLink
            onClick={() =>
              downloadThroughAnchorLink(
                `${INVOICE_API}print-registration-certificate/?id=${item?.id} &table_name=outdoor_advertising`,
                "Registration Certificate"
              )
            }
            title="[Registration Certificate]"
          />
        ),
        actions: (
          <div style={{ whiteSpace: "nowrap" }}>
            <div style={{ whiteSpace: "nowrap" }}>
              <Link
                class="mr-2"
                style={{ padding: 5, paddingBottom: 0 }}
                to={`${OUTDOOR_ADVERTISING}/view/${item.id}`}
              >
                <FaEye />
              </Link>
              <Visibility
                show={
                  item?.status_details === "DEFERRED" &&
                  userRole !== AUDIT_AUTHORITY
                }
              >
                <Link
                  style={{ padding: 5, paddingBottom: 0 }}
                  class=" mr-2"
                  to={`${OUTDOOR_ADVERTISING}/update/${item.id}`}
                >
                  <FaEdit />
                </Link>
              </Visibility>
              <Visibility
                show={
                  ["APPROVED", "PAID"].includes(item?.status_details) &&
                  userRole !== AUDIT_AUTHORITY
                }
              >
                {item?.active_status === 1 && status === "assessed" ? (
                  <>
                    <Tooltip title="Amendment">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`/amendment/outdoor_advertising/${item.id}`}
                      >
                        <FaGem />
                      </Link>
                    </Tooltip>

                    <Tooltip title="Reassessment">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`/reassessment/outdoor_advertising/${item.id}`}
                      >
                        <FaMoneyBill />
                      </Link>
                    </Tooltip>
                    <Tooltip title="Business Transfer">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`../business-transfers/request/outdoor-advertisings/${item.branch_code}`}
                      >
                        <FaAngleDoubleRight />
                      </Link>
                    </Tooltip>
                    <Tooltip title="Objection">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`/objection/outdoor_advertising/${item.id}`}
                      >
                        <PanTool sx={{ fontSize: 16 }} />
                      </Link>
                    </Tooltip>
                    <Visibility
                      show={
                        item?.outstanding_balance == 0 &&
                        userRole !== AUDIT_AUTHORITY
                      }
                    >
                      <Tooltip title="Deactivate">
                        <Link
                          style={{ padding: 5, paddingBottom: 0 }}
                          class=" mr-2"
                          to={`${OUTDOOR_ADVERTISING}/business_deactivation/${item.id}`}
                        >
                          <FaBan />
                        </Link>
                      </Tooltip>
                    </Visibility>
                  </>
                ) : null}
                {
                  (status === "deactivated" && contextData.can_admin === 1) ? (
                      <ActivateButton onClick={() => activateOutdoorAdvertising(item.id)} label="Reactivate" />
                  ) : null
                }
              </Visibility>
            </div>
          </div>
        ),
      };
      return row;
    }),
  ];

  const viewData = (item, isFormData) => {
    return {
      ...returnObject(!isFormData, {
        "Reference Number": item?.application_number,
      }),
      ...returnObject(
        item?.status_details === STATUS_APPROVED ||
          item?.status_details === STATUS_DEACTIVATED,
        {
          "Branch Code": item?.branch_code,
        }
      ),
      tin: item?.tin,
      ...returnObject(isFormData, {
        ...returnObject(item?.ownershipType === 1, {
          nationality: item?.ownershipType === 1 ? "Ugandan" : "Non-ugandan",
          ...returnObject(item?.ownershipType === 1, {
            nin: item?.cin,
          }),
          ...returnObject(item?.ownershipType === 2, {
            Passport: item?.nin,
          }),
        }),
      }),

      ...returnObject(!isFormData, {
        ...returnObject(item?.owner_details?.profile?.is_individual, {
          nin: item?.nin,
          Nationality:
            item?.owner_details?.profile === 1 ? "Ugandan" : "Non-ugandan",
          ...returnObject(item?.owner_details?.profile === 1, {
            nin: item?.nin,
          }),
          ...returnObject(item?.owner_details?.profile === 2, {
            passport: item?.nin,
          }),
        }),
      }),
      ...returnObject(isFormData, {
        ...returnObject(item?.ownershipType === 0, {
          brn: item?.brn,
          "Legal Name": item?.legal_name,
        }),
      }),
      ...returnObject(!isFormData, {
        ...returnObject(!item?.owner_details?.profile?.is_individual, {
          brn: item?.brn,
          tin: item?.tin,
          "Legal Name": item?.legal_name,
        }),
      }),

      email: item?.email,
      mobile_number: item?.mobile_number,
      ...returnObject(!isFormData, {
        [sTaxpayerName]: item?.owner_name,
      }),
      ...returnObject(isFormData, {
        [sTaxpayerName]: !item?.is_individual
          ? item?.legal_name
          : `${item?.first_name} ${item?.surname}`,
      }),
      [sMunicipality]: isFormData
        ? item?.municipality_name
        : item?.municipality_name,
      [sDivision]: isFormData ? item?.division_name : item?.division_name,
      [sParish]: isFormData ? item?.parish_name : item?.parish_name,
      [sVillage]: isFormData ? item?.village_name : item?.village_name,
      [sStreet]: isFormData ? item?.street_name : item?.street_name,
      plot_number: item?.r_plot_no,
      house_number: item?.r_house_no,
      latitude: item?.r_lat,
      longitude: item?.r_lng,
      classification: item?.classification,
      tool_type: isFormData ? item?.tool_type_name : item?.tool_type_name,
      ...returnObject(
        item?.tool_type_name === "Billboards" ||
          item?.tool_type_name === "Sign Post" ||
          item?.tool_type_name === "Trivision signs" ||
          item?.tool_type_name === "Roof signs" ||
          item?.tool_type_name === "Wall adverts",
        {
          tool_size: isFormData ? item?.tool_size_name : item?.tool_size_name,
        }
      ),
      ...returnObject(
        item?.tool_type_name === "Billboards" ||
          item?.tool_type_name === "Sign Post" ||
          item?.tool_type_name === "Trivision signs" ||
          item?.tool_type_name === "Roof signs",
        {
          "Number of Sides": isFormData ? item.side_name : item?.sides_name,
        }
      ),

      material_used: isFormData
        ? item?.material_used_name
        : item?.material_used_name,
      [sheightFromGround]: item?.r_height_off_ground,
      ...returnObject(item?.amount !== undefined && item?.amount !== null, {
        "Annual Amount Assessed (UGX)": item?.amount?.toLocaleString(),
      }),
      annual_charge: Number(item?.amount)?.toLocaleString(),
      ...returnObject(!isFormData, {
        outstanding_balance: Number(
          item?.outstanding_balance
        )?.toLocaleString(),
        // Status: item?.status_details,
      }),
      ...returnObject(!isFormData, {
        "SUPPORTING DOCUMENTS": "",
        ...displayDocuments(item?.outdoor_advertising_attachments),
      }),
      created_by: `${item?.created_by?.first_name} ${item?.created_by?.last_name}`,
    };
  };

  const outdoorAdvertisingTableColumns = (status) => [
    { id: "application_number", header: "Reference Number ", filter: {} },
    ...returnObject(
      status === STATUS_ASSESSED || status === STATUS_DEACTIVATED,
      [{ id: "branch_code", header: "Branch Code", filter: {} }]
    ),
    { id: "owner_name", header: "Taxpayer Name", filter: {} },
    { id: "mobile", header: "Mobile Number ", filter: {} },
    { id: "mobile_number", header: "Telephone Number ", filter: {} },
    { id: "tool_type", header: "Tool Type", filter: {} },
    { id: MUN_NAME, header: "Municipality ", filter: {} },
    { id: DIV_NAME, header: "Division", filter: {} },
    { id: PAR_NAME, header: "Parish ", filter: {} },
    { id: VILL_NAME, header: "Village", filter: {} },
    { id: STR_NAME, header: "Street ", filter: {} },
    { id: "amount", header: "Annual Amount Assessed (UGX)", filter: {} },
    { id: "created_by", header: "Created By", filter: {} },
    ...returnObject(status === STATUS_ASSESSED, [
      { id: "assessments", header: "Invoices" },
      ...returnObject(userRole !== AUDIT_AUTHORITY, [
        {
          id: "prn",
          header: "PRNs",
        },
      ]),
      { id: "certificate", header: "Registration Certificate" },
    ]),
    { id: "actions", header: "Actions" },
  ];

  const getActions = (status, approvedID, deferredID, rejectedID) => {
    status = status?.toLowerCase();

    let options = [];
    switch (status) {
      case STATUS_PENDING?.toLowerCase():
        options = [
          { value: approvedID, label: "Approve" },
          { value: rejectedID, label: "Reject" },
          { value: deferredID, label: "Deferred" },
        ];
        break;

      default:
        options = [];
    }

    return options;
  };

  const getRedirect = (status) => {
    let redirect = "";
    switch (status.toLowerCase()) {
      case STATUS_SUBMITTED:
        redirect = `${OUTDOOR_ADVERTISING}/${STATUS_SUBMITTED}`;
        break;

      case STATUS_ASSESSED:
        redirect = `${OUTDOOR_ADVERTISING}/${STATUS_ASSESSED}`;
        break;

      case STATUS_REJECTED:
        redirect = `${OUTDOOR_ADVERTISING}/${STATUS_REJECTED}`;
        break;

      case STATUS_DEFFERED:
        redirect = `${OUTDOOR_ADVERTISING}/${STATUS_DEFFERED}`;
        break;

      default:
        break;
    }

    return redirect;
  };

  const amendableFields = [
    ...taxpayerAmdendables,

    {
      id: sMunicipality,
      field: "municipality",
      type: "select",
      options: "districts",
    },
    { id: sDivision, field: "division", type: "select", options: "divisions" },
    { id: sParish, field: "parish", type: "select", options: "parishes" },
    { id: sVillage, field: "village", type: "select", options: "villages" },
    { id: sStreet, field: "street", type: "select", options: "streets" },
    { id: "latitude", field: "r_lat", type: "text" },
    { id: "longitude", field: "r_lng", type: "text" },
    {
      id: "tool_type",
      field: "tool_type",
      type: "select",
      options: "toolTypes",
    },
    {
      id: "tool_size",
      field: "tool_size",
      type: "select",
      options: "toolSizes",
    },
    {
      id: "material_used",
      field: "material_used",
      type: "select",
      options: "materialsUsed",
    },
    {
      id: sheightFromGround,
      field: "r_height_off_ground",
      type: "number",
    },
  ];

  const objectionFields = [
    { id: "mobile_number", field: "mobile_number", type: "tel" },
    { id: "tin", field: "tin", type: "text" },
    { id: "email", field: "email", type: "text" },
    {
      id: sMunicipality,
      field: "municipality",
      type: "select",
      options: "districts",
    },
    { id: sDivision, field: "division", type: "select", options: "divisions" },
    { id: sParish, field: "parish", type: "select", options: "parishes" },
    { id: sVillage, field: "village", type: "select", options: "villages" },
    { id: sStreet, field: "street", type: "select", options: "streets" },
    { id: "latitude", field: "r_lat", type: "text" },
    { id: "longitude", field: "r_lng", type: "text" },
    {
      id: "tool_type",
      field: "tool_type",
      type: "select",
      options: "toolTypes",
    },
    {
      id: "tool_size",
      field: "tool_size",
      type: "select",
      options: "toolSizes",
    },
    {
      id: "material_used",
      field: "material_used",
      type: "select",
      options: "materialsUsed",
    },
    {
      id: sheightFromGround,
      field: "r_height_off_ground",
      type: "number",
    },
  ];

  const reassessFields = [
    {
      id: "annual_charge",
      field: "amount",
      type: "number",
    },
  ];

  return {
    outdoorAdvertisingTableColumns,
    outdoorAdvertisingTableData,
    viewData,
    getRedirect,
    getActions,
    amendableFields,
    reassessFields,
    objectionFields,
  };
};

export const useOutdoorAdvertingFormSelect = (form) => {
  const {
    districts,
    getDistricts,
    divisions,
    getDivisions,
    parishes,
    getParishes,
    villages,
    getVillages,
    streets,
    getStreets,
  } = useLocationData();
  const { data: toolTypes, loadData: getToolType } = useGenericGet();
  const { data: toolSizes, loadData: getToolSize } = useGenericGet();
  const { data: sides, loadData: getSides } = useGenericGet();
  const { data: materialsUsed, loadData: getMaterialUsed } = useGenericGet();

  useEffect(() => {
    getDistricts();
  }, []);

  useEffect(() => {
    if (form?.municipality) getDivisions(form?.municipality);
  }, [form?.municipality]);

  useEffect(() => {
    if (form?.division) getParishes(form?.division);
  }, [form?.division]);

  useEffect(() => {
    if (form?.parish) getVillages(form?.parish);
  }, [form?.parish]);

  useEffect(() => {
    if (form?.village) getStreets(form?.village);
  }, [form?.village]);

  useEffect(() => {
    // getCategories({ api: ADVERTISEMENT_CLASSIFICATION_API });
    getToolType({ api: TOOL_TYPES_API });
    getToolSize({ api: TOOL_SIZES_API });
    getSides({ api: SIDES_API });
    getMaterialUsed({
      api: CONFIGURABLES_API,
      params: { field: MATERIAL_USED },
    });
  }, []);

  return {
    districts,
    divisions,
    parishes,
    villages,
    streets,
    toolSizes: toolSizes?.results || [],
    toolTypes: toolTypes?.results || [],
    materialsUsed: materialsUsed?.results || [],
  };
};

export default useOutdoorAdverting;

export const MakePaymentRevenueButton = ({ api, id, revenue_source }) => (
  <Button type="primary" size="small" className="font13">
    <Link
      to={`/assessed-return-period${api}/${id}/${revenue_source}`}
      style={{ color: "white" }}
    >
      Make Payment
    </Link>
  </Button>
);
