import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { parse } from "url";
import useAssessments from "../../../hooks/billing/useAssessments";
import Table from "../../common/Table";
import useGenericGet from "../../../hooks/common/useGenericGet";

const AssessmentsRegister = (props) => {
  const { search, state } = useLocation();
  const [filters, setFilter] = useState({});

  const {
    assessmentsTableData,
    assessmentsTableColumns,
    billSucess,
    billError,
  } = useAssessments();
  const {
    loading,
    loadData: getAssessments,
    data: assessments,
  } = useGenericGet();
  const { query } = parse(search || props.search, true);

  useEffect(() => {
    getAssessments({
      api: `billings/periods/${search || props.search}`,
      params: filters,
    });
  }, [billSucess, filters]);

  return (
    <div>
      <h3>Returns &amp; Assessments</h3>
      <Table
        tableData={assessmentsTableData(
          assessments?.results || [],
          query?.tax,
          query?.tax_id,
          state
        )}
        tableColumns={assessmentsTableColumns}
        count={assessments?.count}
        tableTitle="Assessments"
        loading={loading}
        onFilterChange={setFilter}
        filters={filters}
        success={billSucess}
        error={billError}
      />
    </div>
  );
};

export default AssessmentsRegister;
