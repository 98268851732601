import React, { useEffect, useState } from "react";
import {
  FaAngleDoubleRight,
  FaBan,
  FaEdit,
  FaEye,
  FaGem,
  FaMoneyBill,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import {
  STATUS_APPROVED,
  STATUS_PENDING,
  STATUS_REJECTED,
  STATUS_SUBMITTED,
  STATUS_ASSESSED,
  STATUS_TRADE_LICENSE_APPROVED,
  TRADINDG_LICENSE,
  APPLICATION_FEES,
  WASTE_FEES,
  SIGN_FEES,
  PAID,
  NOT_PAID,
  STATUS_DEACTIVATED,
  NO_LICENSE,
  ACTIVE,
  AUDIT_AUTHORITY,
  PENDING_STATUS,
} from "../../constants/webapp";
import {
  downloadCertificate,
  downloadThroughAnchorLink,
  returnObject,
} from "../../utils/helperUtil";
import { configColor } from "../../components/styled/Theme";
import { Visibility } from "../../components/common/Visibility";
import useLocationData from "../common/useLocationData";
import useGenericGet from "../common/useGenericGet";
import {
  ALL_BUSINESS_CATEGORIES_API,
  ALL_TRADING_LICENSE_AMOUNT_API,
  API_ALL_CONFIGURABLES_,
  INVOICE_API,
  NATURE_OF_BUSINESS_ALL_API,
  NATURE_OF_BUSINESS_API,
  TURNOVER_THRESHOLD_API,
} from "../../constants/api";
import useBusinessCategories from "../business-categories/useBusinessCategories";
import { PanTool } from "@mui/icons-material";
import { Button, Tooltip } from "antd";
import {
  ActivateButton,
  DownloadAnchorButton,
  DownloadLink,
} from "../../components/common/Button";
import {
  CONST_LABEL_LOCAL_GOV,
  LABEL_LST_THREHOLD,
  LABEL_NATURE_OF_BUSINESS,
  LABEL_TURN_OVER_AMOUNT,
} from "../../constants/trading-license";
import {
  sDivision,
  sMunicipality,
  sParish,
  sStreet,
  sTaxpayerName,
  sVillage,
} from "../../components/layouts/LeftMenu";
import moment from "moment";
import { taxpayerAmdendables } from "../../utils/statusUtils";
import { useAppContext } from "../../context/AppContext";
import useRevenueSource from "../revenue-source/useRevenueSource";

const useTradingLicense = () => {
  const { contextData, updateContext } = useAppContext();
  const navigate = useNavigate();
  const { activateRevenueSource } = useRevenueSource();

  const userRole = contextData?.role_name;

  const Assess = (item, status) => {
    navigate(`/assessments?tax_id=${item?.id}&tax=trade_license&status=${status}`, {
      state: { document_data: item },
    });
  };

  const activateRegister = (id) => {
    activateRevenueSource(id, { table_name: "trade_license", id }, '../trading-license/assessed')
  }

  const tradingLicenseTableData = (status, data) => [
    ...data.map((item) => {
      var expiry_date = moment(new Date(item?.license_expiry_date));
      var today = moment(new Date());
      var days =
        expiry_date.diff(today, "days") < 0
          ? 0
          : expiry_date.diff(today, "days");

      const row = {
        date: moment(item?.created_at).format("ll"),
        application_number: item?.application_number,
        branch_code: item?.branch_code,
        owner_name: item?.owner_name,
        trade_title: item?.trade_title,
        mobile_number: item?.mobile_number,
        business_category: item?.trade_category_name,
        nature_of_business: item?.trade_sub_category_name,
        assessed_value: Number(item?.assessed_value)?.toLocaleString(),
        municipality_name: item?.municipality_name,
        division_name: item?.division_name,
        parish_name: item?.parish_name,
        village_name: item?.village_name,
        street_name: item?.street_name,
        r_lat: item?.r_lat,
        r_lng: item?.r_lng,
        // grade: item?.grade,
        shop_number: item?.shop_number,
        payment_status:
          new Date(item?.license_expiry_date) >= new Date()
            ? "Paid"
            : "Not Paid",
        license_status:
          new Date(item?.license_expiry_date) >= new Date()
            ? "Active"
            : "No License",
        license_validity: item?.license_expiry_date ? days : "No License",
        expiry_date: item?.license_expiry_date
          ? item?.license_expiry_date
          : "No License",
        created_by: item?.created_by_name,
        assessments:
          item?.is_operational_permit &&
          item?.operational_permit_expiry &&
          new Date(item?.operational_permit_expiry) >= new Date() ? (
            "Operational Permit Still Active"
          ) : (
            <a
              class="mr-2"
              href=""
              style={{ color: configColor }}
              onClick={() => {
                Assess(item, status);
                updateContext("trade_license_data", item);
                updateContext("receipt_data", item);
              }}
            >
              Assessment
            </a>
          ),

        prn: (
          <MakePaymentRevenueButton
            api={TRADINDG_LICENSE}
            id={item?.id}
            revenue_source={"trade_license"}
          />
        ),
        certificate: (
          <DownloadAnchorButton
            url={`${INVOICE_API}print-registration-certificate/?id=${item?.id} &table_name=trade_license`}
            fileName="certificate"
            title="[Registration Certificate]"
          />
        ),
        actions: (
          <div style={{ whiteSpace: "nowrap" }}>
            <div style={{ whiteSpace: "nowrap" }}>
              <Tooltip title="View">
                <Link
                  class="mr-2"
                  style={{ padding: 5, paddingBottom: 0 }}
                  to={`${TRADINDG_LICENSE}/view/${item.id}`}
                >
                  <FaEye />
                </Link>
              </Tooltip>
              <Visibility
                show={
                  ["DEFERRED", "PENDING"].includes(item?.status_details) &&
                  userRole !== AUDIT_AUTHORITY
                }
              >
                <Tooltip title="Edit">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`${TRADINDG_LICENSE}/update/${item.id}`}
                  >
                    <FaEdit />
                  </Link>
                </Tooltip>
              </Visibility>
              <Visibility
                show={
                  ["APPROVED", "PAID"].includes(item?.status_details) &&
                  userRole !== AUDIT_AUTHORITY
                }
              >
                {status === "assessed" ? (
                  <>
                    <Tooltip title="Amendemt">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`/amendment/trade_license/${item.id}`}
                      >
                        <FaGem />
                      </Link>
                    </Tooltip>

                    <Tooltip title="Reassessment">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`/reassessment/trade_license/${item.id}`}
                      >
                        <FaMoneyBill />
                      </Link>
                    </Tooltip>
                    <Tooltip title="Business Transfer">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`../business-transfers/request/trade-licenses/${item.branch_code}`}
                      >
                        <FaAngleDoubleRight />
                      </Link>
                    </Tooltip>
                    <Tooltip title="Objection">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`/objection/trade_license/${item.id}`}
                      >
                        <PanTool sx={{ fontSize: 16 }} />
                      </Link>
                    </Tooltip>
                    <Visibility show={item?.outstanding_balance == 0}>
                      <Tooltip title="Deactivate">
                        <Link
                          style={{ padding: 5, paddingBottom: 0 }}
                          class=" mr-2"
                          to={`${TRADINDG_LICENSE}/business_deactivation/${item.id}`}
                        >
                          <FaBan />
                        </Link>
                      </Tooltip>
                    </Visibility>
                  </>
                ) : null}
                {
                  (status === "deactivated" && contextData.can_admin === 1) ? (
                      <ActivateButton onClick={() => activateRegister(item.id)} label="Reactivate" />
                  ) : null
                }
              </Visibility>
            </div>
          </div>
        ),
      };
      return row;
    }),
  ];

  const outstandingInvoicesViewData = (item) => {
    return {
      amount_paid: item?.amount_paid,
      payment_status: item?.payment_status,
      period_name: item?.period_name,
      ref: item?.ref,
      amount: item?.amount,
    };
  };

  const viewData = (item, isFormData) => {
    return {
      ...returnObject(!isFormData, {
        "Reference Number": item?.application_number,
      }),
      ...returnObject(
        item?.status_details === STATUS_TRADE_LICENSE_APPROVED ||
          item?.status_details === STATUS_DEACTIVATED,
        {
          "Branch Code": item?.branch_code,
        }
      ),
      ...returnObject(!isFormData, {
        [sTaxpayerName]: item?.owner_name,
      }),
      ...returnObject(isFormData, {
        [sTaxpayerName]: !item?.is_individual
          ? item?.legal_name
          : `${item?.first_name} ${item?.surname}`,
      }),
      brn: item?.brn,
      nin: item?.nin,
      business_name: item?.trade_title,
      mobile_number: item?.mobile_number,
      email: item?.email,
      tin: item?.tin,
      ...returnObject(item?.idtype === 1, {
        nin: item?.cin,
      }),
      ...returnObject(item?.idtype === 2, {
        "Driving Permit": item?.cin,
      }),
      ...returnObject(item?.idtype === 3, {
        Passport: item?.cin,
      }),
      ...returnObject(item?.idtype === 4, {
        "Village ID": item?.cin,
      }),
      [item?.idTypeName]: item?.nin || item?.cin,
      [CONST_LABEL_LOCAL_GOV]: item?.municipality_name,
      [sDivision]: item?.division_name,
      [sParish]: item?.parish_name,
      [sVillage]: item?.village_name,
      [sStreet]: item?.street_name,
      latitude: item?.r_lat,
      longitude: item?.r_lng,
      building_name: item?.building_name,
      shop_number: item?.shop_number,

      house_number: item?.r_house_no,
      building_name: item?.building_name,
      plot_number: item?.r_plot_no,
      brn: item?.brn,
      grade: isFormData ? item?.grade_value : item?.street_grade,
      business_category: isFormData
        ? item?.category_name
        : item?.trade_category_name,
      [LABEL_NATURE_OF_BUSINESS]: isFormData
        ? item?.sub_category_name
        : item?.trade_sub_category_name,

      trade_license_amount: item?.r_trd_amount,
      waste_fees: Number(item?.r_waste_fees).toLocaleString(),
      application_fees: Number(item?.r_app_fees)?.toLocaleString(),
      sign_fees: Number(item?.r_sky_signs)?.toLocaleString(),
      [LABEL_LST_THREHOLD]: item?.r_lst_threshold_details?.name,
      [LABEL_TURN_OVER_AMOUNT]: Number(item?.r_turn_amount)?.toLocaleString(),
      total_amount: Number(item?.assessed_value)?.toLocaleString(),
      payment_status: item?.status_details,
      ...returnObject(!isFormData, {
        outstanding_balance: Number(
          item?.outstanding_balance
        )?.toLocaleString(),
      }),
      created_by: `${item?.created_by?.first_name} ${item?.created_by?.last_name}`,
    };
  };

  const tradingLicenseTableColumns = (status) => [
    { id: "date", header: "Registration Date", filter: { type: "date" } },
    {
      id: "application_number",
      header: "Reference Number ",
      placeholder: "Reference Number",
      filter: {},
    },
    ...returnObject(
      status === STATUS_ASSESSED || status === STATUS_DEACTIVATED,
      [{ id: "branch_code", header: "Branch Code", filter: {} }]
    ),
    { id: "municipality_name", header: "Municipality", filter: {} },
    { id: "division_name", header: "Division", filter: {} },
    { id: "parish_name", header: "Parish ", filter: {} },
    { id: "village_name", header: "Village", filter: {} },
    { id: "street_name", header: "Street", filter: {} },
    // { id: "grade", header: "Grade " },
    { id: "owner_name", header: "Taxpayer Name ", filter: {} },
    { id: "trade_title", header: "Business name ", filter: {} },
    { id: "mobile_number", header: "Mobile Number ", filter: {} },
    { id: "business_category", header: "Business Category ", filter: {} },
    { id: "nature_of_business", header: "Nature of Business", filter: {} },
    { id: "created_by", header: "Created By", filter: {} },
    ...returnObject(status  === STATUS_DEACTIVATED, [
      { id: "assessments", header: "Invoices" },
    ]),
    ...returnObject(status === STATUS_ASSESSED, [
      {
        id: "payment_status",
        header: "Payment Status",
        filter: {
          type: "select",
          placeholder: "Select status...",
          options: [
            { id: NOT_PAID, name: "Not Paid" },
            { id: PAID, name: "Paid" },
          ],
        },
      },

      {
        id: "license_status",
        header: "License Status",
        filter: {
          type: "select",
          placeholder: "Select status...",
          options: [
            { id: NO_LICENSE, name: "No License" },
            { id: ACTIVE, name: "Active" },
          ],
        },
      },
      {
        id: "license_validity",
        header: "License Validity (days)",
        filter: { type: "licenseValidity" },
      },
      {
        id: "expiry_date",
        header: "Expiry Date",
        filter: { type: "expiryDate" },
      },
      { id: "assessed_value", header: "Amount", filter: {} },
      { id: "assessments", header: "Invoices" },
      ...returnObject(userRole !== AUDIT_AUTHORITY, [
        {
          id: "prn",
          header: "PRNs",
        },
      ]),
      { id: "certificate", header: "Registration Certificate" },
    ]),
    { id: "actions", header: "Actions" },
  ];

  const getActions = (status, approvedID, deferredID, rejectedID) => {
    status = status?.toLowerCase();

    let options = [];
    switch (status) {
      case STATUS_PENDING?.toLowerCase():
        options = [
          { value: approvedID, label: "Approve" },
          { value: rejectedID, label: "Reject" },
          { value: deferredID, label: "Deferred" },
        ];
        break;

      default:
        options = [];
    }

    return options;
  };

  const getRedirect = (status) => {
    let redirect = "";
    switch (status.toLowerCase()) {
      case STATUS_SUBMITTED:
        redirect = `${TRADINDG_LICENSE}/${STATUS_ASSESSED}`;
        break;

      case STATUS_APPROVED:
        redirect = `${TRADINDG_LICENSE}/${STATUS_APPROVED}`;
        break;

      case STATUS_REJECTED:
        redirect = `${TRADINDG_LICENSE}/${STATUS_REJECTED}`;
        break;

      default:
        redirect = `${TRADINDG_LICENSE}/${STATUS_PENDING}`;
        break;
    }

    return redirect;
  };

  const amendableFields = [
    ...taxpayerAmdendables,

    {
      id: "business_name",
      field: "trade_title",
      type: "text",
    },
    { id: "building_name", field: "building_name", type: "text" },
    { id: "shop_number", field: "shop_number", type: "text" },
    // { id: "latitude", field: "r_lat", type: "number" },
    // { id: "longitude", field: "r_lng", type: "number" },
    { id: "house_number", field: "r_house_no", type: "text" },
    { id: "building_name", field: "building_name", type: "text" },
    { id: "plot_number", field: "r_plot_no", type: "text" },
  ];

  const objectionFields = [
    //Objection on No value
    { id: "mobile_number", field: "mobile_number", type: "tel" },
    { id: "tin", field: "tin", type: "text" },
    { id: "email", field: "email", type: "text" },
    { id: "building_name", field: "building_name", type: "text" },
    { id: "shop_number", field: "shop_number", type: "text" },
    { id: "tax_payer_name", field: "owner_name", type: "text" },
    { id: "latitude", field: "r_lat", type: "number" },
    { id: "longitude", field: "r_lng", type: "number" },
    { id: "house_number", field: "r_house_no", type: "text" },
    { id: "building_name", field: "building_name", type: "text" },
    { id: "plot_number", field: "r_plot_no", type: "text" },
    { id: "brn", field: "brn", type: "text" },

    //Objection On value
    {
      id: CONST_LABEL_LOCAL_GOV,
      field: "municipality",
      type: "select",
      options: "districts",
    },
    {
      id: sDivision,
      field: "division",
      type: "select",
      options: "divisions",
    },
    { id: sParish, field: "parish", type: "select", options: "parishes" },
    {
      id: sVillage,
      field: "village",
      type: "select",
      options: "villages",
    },
    { id: sStreet, field: "street", type: "select", options: "streets" },
    {
      id: "business_category",
      field: "trade_category",
      type: "select",
      options: "categories",
    },
    {
      id: LABEL_NATURE_OF_BUSINESS,
      field: "trade_subcategory",
      type: "select",
      options: "natureofbusiness",
    },
    {
      id: "turnover_threshold",
      field: "r_lst_threshold",
      type: "select",
      options: "natureofbusiness",
    },
    {
      id: "trade_license_amount",
      field: "r_trd_amount",
      type: "number",
      props: { readOnly: true },
    },
    {
      id: "waste_fees",
      field: "r_waste_fees",
      type: "select",
      options: "waste_fees",
    },
    {
      id: "sign_fees",
      field: "r_sky_signs",
      type: "select",
      options: "sign_fees",
    },
    {
      id: "application_fees",
      field: "r_app_fees",
      type: "select",
      options: "application_fees",
    },
    { id: "grade", field: "grade", type: "text", props: { readOnly: true } },
    {
      id: LABEL_TURN_OVER_AMOUNT,
      field: "r_turn_amount",
      type: "text",
      props: { readOnly: true },
    },
    {
      id: "total_amount",
      field: "assessed_value",
      type: "text",
      props: { readOnly: true },
    },
  ];

  const reassessFields = [
    {
      id: CONST_LABEL_LOCAL_GOV,
      field: "municipality",
      type: "select",
      options: "districts",
    },
    {
      id: sDivision,
      field: "division",
      type: "select",
      options: "divisions",
    },
    { id: sParish, field: "parish", type: "select", options: "parishes" },
    {
      id: sVillage,
      field: "village",
      type: "select",
      options: "villages",
    },
    { id: sStreet, field: "street", type: "select", options: "streets" },
    { id: "latitude", field: "r_lat", type: "number" },
    { id: "longitude", field: "r_lng", type: "number" },
    {
      id: "business_category",
      field: "trade_category",
      type: "select",
      options: "categories",
    },
    {
      id: LABEL_NATURE_OF_BUSINESS,
      field: "trade_subcategory",
      type: "select",
      options: "natureofbusiness",
    },
    {
      id: LABEL_LST_THREHOLD,
      field: "r_lst_threshold",
      type: "select",
      options: "r_lst_threshold",
    },
    {
      id: "trade_license_amount",
      field: "r_trd_amount",
      type: "number",
      props: { readOnly: true },
    },
    {
      id: "waste_fees",
      field: "r_waste_fees",
      type: "select",
      options: "waste_fees",
    },
    {
      id: "sign_fees",
      field: "r_sky_signs",
      type: "select",
      options: "sign_fees",
    },
    {
      id: "application_fees",
      field: "r_app_fees",
      type: "select",
      options: "application_fees",
    },
    { id: "grade", field: "grade", type: "text", props: { readOnly: true } },
    {
      id: LABEL_TURN_OVER_AMOUNT,
      field: "r_turn_amount",
      type: "text",
      props: { readOnly: true },
    },
    {
      id: "total_amount",
      field: "assessed_value",
      type: "text",
      props: { readOnly: true },
    },
  ];

  return {
    tradingLicenseTableColumns,
    tradingLicenseTableData,
    getActions,
    viewData,
    getRedirect,
    amendableFields,
    objectionFields,
    reassessFields,
    outstandingInvoicesViewData,
  };
};

export const useTradingLicenseFormSelect = (form, updateFormValue) => {
  const {
    districts,
    getDistricts,
    divisions,
    getDivisions,
    parishes,
    getParishes,
    villages,
    getVillages,
    streets,
    getStreets,
  } = useLocationData();
  const { data: application_fees, loadData: getApplicationFees } =
    useGenericGet();
  const { data: r_lst_threshold, loadData: getTurnoverThreshold } =
    useGenericGet();
  const { data: waste_fees, loadData: getWasteFees } = useGenericGet();
  const { data: sign_fees, loadData: getSignFees } = useGenericGet();
  const { data: trd, loadData: getLicenseAmount } = useGenericGet();
  const { data: categories, loadData: getBusinessCateogories } =
    useGenericGet();
  const { data: natureofbusiness, loadData: getNatureOfBusiness } =
    useGenericGet();

  useEffect(() => {
    getDistricts();
  }, []);

  useEffect(() => {
    if (form?.municipality) getDivisions(form?.municipality);
  }, [form?.municipality]);

  useEffect(() => {
    if (form?.division) getParishes(form?.division);
  }, [form?.division]);

  useEffect(() => {
    if (form?.parish) getVillages(form?.parish);
  }, [form?.parish]);

  useEffect(() => {
    if (form?.village) getStreets(form?.village);
  }, [form?.village]);

  useEffect(() => {
    getBusinessCateogories({ api: ALL_BUSINESS_CATEGORIES_API });
    getApplicationFees({
      api: API_ALL_CONFIGURABLES_,
      params: { field: APPLICATION_FEES },
    });
    getTurnoverThreshold({ api: TURNOVER_THRESHOLD_API });
    getWasteFees({
      api: API_ALL_CONFIGURABLES_,
      params: { field: WASTE_FEES },
    });
    getSignFees({ api: API_ALL_CONFIGURABLES_, params: { field: SIGN_FEES } });
  }, []);

  useEffect(() => {
    if (form.trade_category) {
      getNatureOfBusiness({
        api: NATURE_OF_BUSINESS_ALL_API,
        params: { parent: form.trade_category },
      });
    }
  }, [form?.trade_category]);

  useEffect(() => {
    updateFormValue(
      "grade",
      (streets || [])
        ?.filter((item) => item.id === Number(form?.street))
        .reduce((acc, curr) => curr.grade, 0)
    );
  }, [streets, form?.street]);

  useEffect(() => {
    updateFormValue(
      "r_turn_amount",
      (r_lst_threshold?.results || [])
        ?.filter((item) => item.id === Number(form?.r_lst_threshold))
        .reduce((acc, curr) => curr.amount, 0)
    );
  }, [r_lst_threshold, form?.r_lst_threshold]);

  useEffect(() => {
    updateFormValue(
      "assessed_value",
      Number(form?.r_turn_amount || 0) +
        Number(form?.r_waste_fees) +
        Number(form?.r_app_fees) +
        Number(form?.r_sky_signs) +
        Number(form?.r_trd_amount || 0)
    );
  }, [
    form?.r_turn_amount,
    form?.r_waste_fees,
    form?.r_sky_signs,
    form?.r_trd_amount,
    form?.r_app_fees,
  ]);

  useEffect(() => {
    if (form?.grade && form?.trade_subcategory) {
      getLicenseAmount({
        api: ALL_TRADING_LICENSE_AMOUNT_API,
        params: {
          grade: form?.grade,
          category: form?.trade_subcategory,
        },
      });
    }
  }, [form?.grade, form?.trade_subcategory]);

  useEffect(() => {
    if (trd)
      updateFormValue(
        "r_trd_amount",
        (trd || []).reduce((acc, curr) => curr.amount, 0)
      );
  }, [trd]);

  return {
    districts,
    divisions,
    parishes,
    villages,
    streets,
    application_fees: (application_fees || []).map((item) => ({
      id: item.name,
      name: item.name,
    })),
    r_lst_threshold: r_lst_threshold?.results || [],
    waste_fees: (waste_fees || []).map((item) => ({
      id: item.name,
      name: item.name,
    })),
    sign_fees: (sign_fees || []).map((item) => ({
      id: item.name,
      name: item.name,
    })),
    categories: categories || [],
    natureofbusiness: natureofbusiness || [],
  };
};

export default useTradingLicense;

export const ObjectionsButton = ({ branch_code, revenue_source }) => (
  <Tooltip title="Objections">
    <Link
      style={{ padding: 5 }}
      class=" mr-2"
      state={{
        objectionData: {
          branch_code: branch_code,
          revenue_source: revenue_source,
        },
      }}
      to="/objections/request"
    >
      <PanTool sx={{ fontSize: 16 }} />
    </Link>
  </Tooltip>
);

export const MakePaymentButton = ({ invoices, status }) => (
  <Button type="primary" size="small" className="font13">
    <Link
      to={`/assessed-return-period`}
      style={{ color: "white" }}
      state={{ outstanding_invoices: invoices, status: status }}
    >
      Make Payment
    </Link>
  </Button>
);

export const MakePaymentRevenueButton = ({ api, id, revenue_source }) => (
  <Button type="primary" size="small" className="font13">
    <Link
      to={`/assessed-return-period${api}/${id}/${revenue_source}`}
      style={{ color: "white" }}
    >
      Make Payment
    </Link>
  </Button>
);
