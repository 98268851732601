import { Info } from "@mui/icons-material";
import { Box, Stack, alpha, darken } from "@mui/material";
import { configColor, configTertiary } from "../../styled/Theme";
import styled from "styled-components";
import { StatusChart } from "./Charts";
import StatTable from "./Tables";
import { getDashboard } from "./DashboardConfig";
import Loader from "../../common/Loader";
import { ReportFilter } from "./ReportFilter";
import { useAppContext } from "../../../context/AppContext";
import { useLocation } from "react-router-dom";
import {
  STATUS_REVENUE_SHARING_RATIO
} from "../../../constants/webapp";
import { DropdownPopup } from "../../common/dropdowns/DropdownPopup";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import {
  REVENUE_SHARING_RATIO_REPORT_API
} from "../../../constants/api";
import { downloadThroughAnchorLink } from "../../../utils/helperUtil";
import { FaFileCsv, FaFileExcel } from "react-icons/fa";

export const SectionTitle = ({ title, tag, info = false }) =>
  title && (
    <div
      className="font15 semiBold flexNullCenter gap10"
      style={{ marginBottom: 15 }}
    >
      {title}
      {info && (
        <div className="greyColor flexCenter">
          <Info fontSize="small" />
        </div>
      )}
      {tag && <TagContainer>{tag}</TagContainer>}
    </div>
  );

export const TopSection = ({ tileData, components }) => {
  // tile section
  const TileSection = ({ data }) => {
    const { sectionTitle, tileData, infoTag } = data;

    return (
      <Box>
        {/* Section Title */}
        {sectionTitle && (
          <SectionTitle title={sectionTitle} tag={infoTag} info />
        )}

        <GridBox className="flex gap20">
          {/* Tiles */}
          {tileData?.map((val, i) => {
            const { title, value, desc, Icon } = val || {};
            return (
              <TileItem className="animate" index={i} key={i}>
                {Icon && (
                  <Icon
                    style={{
                      width: 48,
                      minWidth: 48,
                      height: 48,
                      fontSize: 48,
                    }}
                  />
                )}
                <Box marginLeft={2}>
                  <p className="font15 semiBold">{title.toUpperCase()}</p>
                  <b
                    className="font25"
                    style={{
                      overflowWrap: "anywhere",
                    }}
                  >
                    {value}
                  </b>
                  <p className="font12">{desc}</p>
                </Box>
              </TileItem>
            );
          })}
        </GridBox>
      </Box>
    );
  };

  return (
    <Stack
      sx={{
        backgroundColor: "white",
        p: { xs: 2, sm: 3 },
        borderRadius: 2,
        gap: 3,
      }}
    >
      {/* other components */}
      {components}
      {/* Tile Sections */}
      {tileData?.map((val) => {
        return <TileSection data={val} key={val} />;
      })}
    </Stack>
  );
};

export function DashboardContent({ status }) {
  const StatSection = ({ statData }) => {
    const { title, table, graph, components } = statData;
    const location = useLocation();
    
    const createRevenueBudgetDownloadOption = (title, Icon, api, form) => {
      return {
        title: title,
        Icon: Icon,
        click: () => {
          downloadThroughAnchorLink(api, "Revenue Sharing Report", false, form);
        },
      };
    }

    const getRevenueBudgetDownloadOptions = () => {
      let data = [
        createRevenueBudgetDownloadOption(
          "Excel", <FaFileExcel />, 
          `${REVENUE_SHARING_RATIO_REPORT_API}excel`,
          table?.filter?.form 
        ),
        createRevenueBudgetDownloadOption(
          "CSV", <FaFileCsv />, 
          `${REVENUE_SHARING_RATIO_REPORT_API}csv`,
          table?.filter?.form 
        )
      ];
      return data;
    }

    // component overides
    if (components)
      return (
        <DashboardGridWrapper>
          {components?.map((component) => (
            <StatTile>{component}</StatTile>
          ))}
        </DashboardGridWrapper>
      );

    return location?.pathname === "/reports/ifms" ||
      location?.pathname === "/reports/ifms-lgs" ? (
      <IfmisDashboardGridWrapper>
        <StatTile>
          <SectionTitle title={title[0][0]} tag={title[0][1]} />

          {/* Filter */}
          {table?.rows && <ReportFilter filter={table?.filter} />}

          {/* Table  */}
          <DashboardStatTable table={table} />
        </StatTile>
      </IfmisDashboardGridWrapper>
    ) : (
      <>
        {status === STATUS_REVENUE_SHARING_RATIO ?
          <StatTile>
            <SectionTitle title={title[0][0]} tag={title[0][1]} />

            {/* Filter */}
            {table?.rows && <ReportFilter filter={table?.filter} status={status}/>}

            <DownloadRevenueSharingReport>
              <DropdownPopup menu={getRevenueBudgetDownloadOptions()}>
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "green",
                  }}
                  icon={<DownloadOutlined />}
                >
                  Download
                </Button>
              </DropdownPopup>
            </DownloadRevenueSharingReport>

            {/* Table  */}
            <DashboardStatTable table={table} status={status} />
          </StatTile>
        :
          <DashboardGridWrapper>
            <StatTile>
              <SectionTitle title={title[0][0]} tag={title[0][1]} />

              {/* Filter */}
              {table?.rows && <ReportFilter filter={table?.filter} status={status}/>}

              {/* Table  */}
              <DashboardStatTable table={table} status={status} />
            </StatTile>
            {
              status !== STATUS_REVENUE_SHARING_RATIO &&
              <StatTile>
                {/* Bar Graph */}
                <DashboardStatGraph
                  graph={graph}
                  title={title[1][0]}
                  tag={title[1][1]}
                  status={status}
                />
              </StatTile>
            }
          </DashboardGridWrapper>
        }
      </>
    );
  };

  // get the role id
  const { contextData } = useAppContext() || {};
  const { role_details } = contextData;

  // get dashboard
  const { tileData, statData, component, components } = getDashboard(
    role_details?.name || role_details?.id,
    status
  );

  // const { tileData, statData } = ReportsSummary();

  return component ? (
    component
  ) : (
    <div className="flex flexColumn gap10">
      {tileData?.length > 0 && (
        <TopSection tileData={tileData} components={components} />
      )}
      <StatSection statData={statData} />
    </div>
  );
}

const TileItem = styled.div`
  width: 100%;
  align-items: center;
  padding: 15px 20px;
  line-height: 1.5;
  border-radius: 10px;
  display: flex;
  color: ${(props) =>
    props.index & 1 ? "white" : alpha(darken("#000", 0.6), 0.7)};
  background-color: ${(props) =>
    props.index & 1 ? configColor : configTertiary};
  :hover {
    transform: scale(1.03);
  }
`;

const StatTile = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  // @media (max-width: 990px) {
  //   width: 100%;
  // }
`;

export const SectionBox = styled.div`
  min-height: 400px;
  overflow: hidden;
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  @media (max-width: 1024px) {
    min-height: 0;
  }
`;

const GridBox = styled.div`
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const TagContainer = styled.span`
  background-color: ${configColor};
  color: white;
  font-size: 11px;
  padding: 7px 15px;
  text-align: center;
  border-radius: 30px;
`;

const DashboardGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
  @media screen and (max-width: 860px) {
    display: flex;
    flex-direction: column;
  }
`;

const IfmisDashboardGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 10px;
  @media screen and (max-width: 860px) {
    display: flex;
    flex-direction: column;
  }
`;

const DownloadRevenueSharingReport = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
`;

export const DashboardStatTable = ({ table, title, tag, status }) => (
  <>
    <SectionTitle title={title} tag={tag} />
    <SectionBox>
      {table?.rows ? <StatTable table={table} status={status}/> : <Loader />}
    </SectionBox>
  </>
);

export const DashboardStatGraph = ({ graph, title, tag, status }) => (
  <>
    <SectionTitle title={title} tag={tag} />
    <SectionBox style={{ display: "flex", height: "calc(100% - 50px)" }}>
      {graph ? <StatusChart graph={graph} status={status} /> : <Loader />}
    </SectionBox>
  </>
);
